import React, { useState } from "react";
import Button from "./Button";
import ConnectWallet from "./ConnectWallet";
import Terms from "./Terms";
import SelfieCropper from "./SelfieCropper";
import utils from "./Utils";
import calcGas from "./CalcGas";
import melm from "./melm.svg";
import loader from "./loader.svg";
import redo from "./redo.svg";
import camera from "./camera.svg";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";
import { MintedProduct } from "./MintedProduct";
import { ImportIntoMetaMask } from "./ImportIntoMetaMask";

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

const AURAS = [
  "product_A",
  "product_B",
  "product_C",
  "product_D",
  "product_E",
  "product_F",
  "product_G",
  "product_I",
  "product_J",
  "product_K",
  "MELM"
];

const CREATE_STATE = {
  INTRO: "intro",
  CONNECT_METAMASK: "connect-metamask",
  ENTER_EMAIL: "enter-email",
  ENTER_PID: "enter-pid",
  SELECT_SM: "select-sm",
  SELFIE_INSTRUCTIONS: "selfie-instructions",
  TAKE_SELFIE: "take-selfie",
  CROP_SELFIE: "crop-selfie",
  IMPORT_TO_MM: "import-to-mm",
  SUCCESS: "success",
  CREDITS: "credits",
  PRODUCT_ALREADY_CREATED: "product-already-created",
  PRODUCT_ALREADY_ORDERED: "product-already-ordered",
  PRODUCTS_CREATED: "products-created",
  VIEW_PRODUCTS: "view-product",
  ORDER: "order",
  ORDER_NFT: "order-nft",
  ORDER_PRINT: "order-print",
  ORDER_PAINT: "order-paint",
  ORDER_PLACED: "order-placed",
  PRODUCT_ALREADY_MINTED: "product-already-minted",
  CONFIRM_MINT: "confirm-mint",
  SELECT_PRODUCT_TYPE: "select-product-type"
};

export default function Create({ onAlert, onClose }) {
  const [nextState, setNextState] = useState(CREATE_STATE.INTRO);
  const [backState, setBackState] = useState(CREATE_STATE.INTRO);
  const [email, setEmail] = useState("");
  const [selectedAccount, setSelectedAccount] = useState();
  const [cropInfo, setCropInfo] = useState();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showProduct, setShowProduct] = useState();
  const [pid, setPid] = useState("");
  const [file, setFile] = useState();
  const [selfieSrc, setSelfieSrc] = useState();
  const [orderUrl, setOrderUrl] = useState();
  const [productUrlA, setProductUrlA] = useState();
  const [productUrlB, setProductUrlB] = useState();
  const [mintedProduct, setMintedProduct] = useState();
  const [tokenId, setTokenId] = useState(undefined);
  const [openSeaNftUrl, setOpenSeaNftUrl] = useState(undefined);
  const [ssmChoices, setSsmChoices] = useState([]);
  const [ssmNone, setSsmNone] = useState(false);
  const [isProductApproved, setIsProductApproved] = useState();
  const [mintCode, setMintCode] = useState("");
  const [mintCodeSent, setMintCodeSent] = useState(false);
  const [busy, setBusy] = useState(false);
  const [postPrint, setPostPrint] = useState(false);
  const [printSize, setPrintSize] = useState("A1");
  const [orderName, setOrderName] = useState("");
  const [productType, setProductType] = useState();
  const [version, setVersion] = useState();
  const [staticVersions, setStaticVersions] = useState();

  const resetVars = () => {
    setNextState(CREATE_STATE.INTRO);
    setBackState(CREATE_STATE.INTRO);
    setEmail("");
    setSelectedAccount();
    setCropInfo("");
    setTermsAccepted("");
    setShowProduct();
    setPid("");
    setFile("");
    setSelfieSrc();
    setOrderUrl();
    setProductUrlA();
    setProductUrlB();
    setMintedProduct();
    setTokenId();
    setOpenSeaNftUrl();
    setSsmChoices([]);
    setSsmNone(false);
    setIsProductApproved();
    setVersion();
    setMintCode("");
    setMintCodeSent(false);
    setBusy(false);
    setPostPrint(false);
    setPrintSize("A1");
    setOrderName("");
    setProductType();
    setStaticVersions();
  };

  function handleSsmChoice(choice) {
    const ssmLocal = [...ssmChoices];
    const index = ssmLocal.indexOf(choice);
    if (index > -1) {
      ssmLocal.splice(index, 1);
    } else {
      ssmLocal.push(choice);
    }
    setSsmNone(false);
    setSsmChoices(ssmLocal);
  }

  const handleMint = async (version) => {
    try {
      setBusy(true);
      let fileVersion = version;

      console.log("Minting: ", pid, fileVersion);
      const statusResponse = await fetch(
        utils.getEndPoint("status", `email=${encodeURIComponent(email.toLowerCase())}`)
      );

      if (!statusResponse.ok)
        throw Error(`'status' endpoint GET error. ${await statusResponse.text()}`);

      const { products, type } = await statusResponse.json();

      if (type === "static") fileVersion = staticVersions[version];

      if (!products) {
        onAlert({
          title: <h2>ARRRGGGH! MINT Error!</h2>,
          body: "Something went terribly wrong, there's no Products!? Give it another shot?"
        });
        return;
      }

      const mintedProduct = products.find((o) => o.version === fileVersion);

      if (!mintedProduct) {
        onAlert({
          title: <h2>ARRRGGGH! MINT Error!</h2>,
          body: "Whoa! Can't seem to find product!? Weird, try again?"
        });
        return;
      }

      // will just overwrite if already exists
      const uploadIpfsResponse = await fetch(utils.getEndPoint("ipfs"), {
        method: "POST",
        body: JSON.stringify({ pid, version: fileVersion, type })
      });

      if (!uploadIpfsResponse.ok)
        throw Error(`'ipfs' endpoint POST error. ${await uploadIpfsResponse.text()}`);

      const { imageCid, metadataCid } = await uploadIpfsResponse.json();

      console.log(`Product ${pid}-${fileVersion} CID: `, imageCid);
      console.log(`Metadata ${pid}-${fileVersion} CID: `, metadataCid);

      await signedMint({ metadataCid, imageCid });

      setNextState(CREATE_STATE.SUCCESS);
    } catch (error) {
      console.log("Message: ", error.message);
      onAlert({
        title: <h2>"Whoops!?</h2>,
        body: (
          <>
            Computer says no! Give it another go, blockchains are weird.
            <p>
              If it keeps happening, don't worry your products are saved. You can come back later,
              enter your email and your products will loaded.
            </p>
          </>
        )
      });
      const ethersParsed = getParsedEthersError(error);
      console.log("Ethers parsed error: ", ethersParsed);
      const entry = {
        type: "ERROR",
        description: "Minting",
        pid,
        version,
        email,
        error: {
          ethersParsed,
          error
        }
      };
      console.log("Entry: ", entry);
      await utils.log(entry);
    } finally {
      setBusy(false);
    }
  };

  const getEthSignedHash = async (msgObj) => {
    const nonce = new Uint32Array(1);
    window.crypto.getRandomValues(nonce);

    const response = await fetch(utils.getEndPoint("ethSignedHash"), {
      method: "POST",
      body: JSON.stringify({ message: JSON.stringify(msgObj), nonce: nonce[0] })
    });

    if (!response.ok) throw Error(`'ethSignedHash' endpoint POST error. ${await response.text()}`);
    return await response.json();
  };

  const signedMint = async ({ metadataCid, imageCid }) => {
    console.log("Signing...");
    const { hash, signature } = await getEthSignedHash({ pid });

    console.log("Hash: ", hash);

    const r = signature.slice(0, 66);
    const s = "0x" + signature.slice(66, 130);
    const v = parseInt(signature.slice(130, 132), 16);

    console.log("r, s, v: ", r, s, v);

    console.log("Minting with Signature...");
    const to = selectedAccount;
    const contract = utils.getMetamaskProviderContract();
    const cost = process.env.REACT_APP_COST_NFT_WEI;

    const gasParams = {
      contract,
      methodName: "signedMint",
      args: { to, uri: metadataCid, msgHash: hash, v, r, s },
      cost
    };
    console.log("Gas params:", gasParams);

    console.log("Calculating gas...");
    const gas = await calcGas(gasParams);
    console.log("Calculated gas: ", gas);

    const transferTx = await contract[gasParams.methodName](...Object.values(gasParams.args), {
      ...gas,
      value: cost
    });

    console.log("Minting...");
    const result = await transferTx.wait();
    console.log("Result: ", result);
    const tokenId = parseInt(result.logs[1].topics[3], 16);

    console.log("TokenId: ", tokenId);
    setTokenId(tokenId);
    const osNftUrl = `${process.env.REACT_APP_OPENSEA_NFT_BASE_URL}/${CONTRACT_ADDRESS}/${tokenId}`;
    console.log("OpenSea token url:", osNftUrl);
    setOpenSeaNftUrl(osNftUrl);

    await updateMinted({
      pid,
      metadataCid,
      imageCid,
      owner: selectedAccount,
      tokenId
    });
  };

  const updateMinted = async (params) => {
    const updateMintedResponse = await fetch(utils.getEndPoint("minted"), {
      method: "POST",
      body: JSON.stringify(params)
    });

    if (!updateMintedResponse.ok)
      throw Error(`'minted' endpoint POST error. ${await updateMintedResponse.text()}`);
  };

  const createPresignedUrl = async (pid) => {
    const response = await fetch(utils.getEndPoint("signedurl"), {
      method: "POST",
      body: JSON.stringify({ pid, fileType: file.type })
    });

    if (!response.ok) throw Error(`'signedurl' endpoint POST error. ${await response.text()}`);

    return await response.text();
  };

  const createStaticProducts = async (pid) => {
    const createBOverrides = () => {
      const rnd = !!Math.floor(Math.random() * 2);
      return {
        base: {
          flop: rnd
        },
        el: {
          flip: rnd,
          flop: true
        },
        er: {
          flip: !rnd,
          flop: true
        },
        nose: {
          flop: rnd
        },
        mouth: {
          flip: true,
          flop: rnd
        },
        aura: {
          flop: rnd
        }
      };
    };

    const payload = {
      pid,
      auras: AURAS,
      type: "static"
    };

    const payloadA = {
      ...payload
    };

    console.log("Payload A: ", payloadA);

    const payloadB = {
      ...payload,
      overrides: createBOverrides()
    };

    console.log("Payload B: ", payloadB);

    return await createProductPost(payloadA, payloadB, "static");
  };

  const createSelfieProducts = async (pid, useExistingSelfie) => {
    if (!useExistingSelfie) {
      console.log("Uploading selfie...");
      const signedUrl = await createPresignedUrl(pid);

      console.log("Signed url: ", signedUrl);

      const putSelfieResponse = await fetch(signedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file.type
        },
        body: await readFile(file, true)
      });

      if (!putSelfieResponse.ok)
        throw Error(
          `'createPresignedUrl' endpoint PUT error. Could not upload selfie. ${await putSelfieResponse.text()}`
        );
    }

    const parts = ["el", "er", "m", "n"];
    const partB = parts[Math.floor(Math.random() * parts.length)];

    const createBOverrides = () => {
      const rnd = !!Math.floor(Math.random() * 2);
      return {
        base: {
          flop: rnd
        },
        el: {
          pid: partB === "el" ? pid : undefined
        },
        er: {
          pid: partB === "er" ? pid : undefined
        },
        nose: {
          pid: partB === "n" ? pid : undefined,
          flop: rnd
        },
        mouth: {
          pid: partB === "m" ? pid : undefined,
          flop: rnd
        },
        aura: {
          flop: rnd
        }
      };
    };

    const payload = {
      pid,
      auras: AURAS,
      useExistingSelfie,
      type: "selfie",
      cropInfo,
      fileType: useExistingSelfie ? undefined : file.type,
      ssmChoices
    };

    const payloadA = {
      version: "A",
      overrides: {
        base: {
          pid
        }
      },
      ...payload
    };

    console.log("Payload A: ", payloadA);

    const payloadB = {
      version: "B",
      overrides: createBOverrides(),
      ...payload
    };

    console.log("Payload B: ", payloadB);

    return await createProductPost(payloadA, payloadB, "selfie");
  };

  const createProductPost = async (payloadA, payloadB, type) => {
    const createPromiseA = fetch(utils.getEndPoint("product"), {
      method: "POST",
      body: JSON.stringify(payloadA)
    });

    const createPromiseB = fetch(utils.getEndPoint("product"), {
      method: "POST",
      body: JSON.stringify(payloadB)
    });

    const [createResponseA, createResponseB] = await Promise.all([createPromiseA, createPromiseB]);

    if (!createResponseA.ok)
      throw Error(
        `'product' endpoint POST error. Create Product A failed! ${await createResponseA.text()}`
      );
    if (!createResponseB.ok)
      throw Error(
        `'product' endpoint POST error. Create Product B failed! ${await createResponseB.text()}`
      );

    const { watermarkedProductSignedUrl: urlA, version: versionA } = await createResponseA.json();
    console.log("Watermarked Product A signed URL: ", urlA);
    const { watermarkedProductSignedUrl: urlB, version: versionB } = await createResponseB.json();
    console.log("Watermarked Product B signed URL: ", urlB);

    setStaticVersions(type === "static" ? { A: versionA, B: versionB } : undefined);

    return { urlA, urlB };
  };

  /* const sendMintCode = async () => {
    try {
      setMintCodeSent(false);
      console.log("Sending mint code");
      const mintCodePostResponse = await fetch(utils.getEndPoint("mintcode"), {
        method: "POST",
        body: JSON.stringify({ email, pid })
      });

      if (!mintCodePostResponse.ok)
        throw Error(`'mintcode' endpoint POST error. ${await mintCodePostResponse.text()}`);

      console.log("DONE!");
      setMintCodeSent(true);
    } catch (error) {
      onAlert({ title: <h2>Whoa!?</h2>, body: "That wasn't supposed to happen??! Panic!" });
      setMintCodeSent(false);
      const entry = {
        type: "ERROR",
        description: "Sending Mint Code",
        pid,
        version,
        email,
        error
      };
      console.log("Entry: ", entry);
      await utils.log(entry);
    }
  };

  const handleMintCodeChange = (e) => {
    setMintCodeSent(false);
    setMintCode(e.target.value.trim());
  };

  const handleCheckMintCode = async () => {
    //setNextState(CREATE_STATE.VIEW_PRODUCTS);
    //return;
    if (!mintCode) return;
    try {
      setBusy(true);
      console.log("Checking mint code: ", mintCode);
      const mintCodeGetResponse = await fetch(
        utils.getEndPoint(
          "mintcode",
          `email=${encodeURIComponent(email.toLowerCase())}&code=${mintCode}`
        )
      );
      if (!mintCodeGetResponse.ok)
        throw Error(`'mintcode' endpoint GET error. ${await mintCodeGetResponse.text()}`);

      const { valid } = await mintCodeGetResponse.json();
      console.log("Is Mint code valid?: ", valid);

      if (valid) {
        setNextState(CREATE_STATE.VIEW_PRODUCTS);
      } else {
        onAlert({ title: "Mint Code Error", body: "Code is invalid or expired." });
        return;
      }
    } catch (error) {
      onAlert({ title: "Whoa!?", body: "That wasn't supposed to happen??! Panic!" });
      const entry = {
        type: "ERROR",
        description: "Mint Code",
        pid,
        version,
        email,
        error
      };
      console.log("Entry: ", entry);
      await utils.log(entry);
    } finally {
      setBusy(false);
    }
  };

  const handleResendMintCode = async () => {
    try {
      setBusy(true);
      await sendMintCode();
    } catch {
    } finally {
      setBusy(false);
    }
  };*/

  const getPrintPrice = () => {
    switch (printSize) {
      default:
        throw Error("No print size: ", printSize);
      case "A1":
        return process.env.REACT_APP_COST_NFT_PRINT_DOLLARS_A1;
      case "A2":
        return process.env.REACT_APP_COST_NFT_PRINT_DOLLARS_A2;
      case "A3":
        return process.env.REACT_APP_COST_NFT_PRINT_DOLLARS_A3;
    }
  };

  const handlePlaceOrder = async (isPaint) => {
    try {
      setBusy(true);
      console.log("Sending order...");
      const packaging = process.env.REACT_APP_COST_NFT_PRINT_PACKAGING;
      const postage = process.env.REACT_APP_COST_NFT_PRINT_POSTAGE;
      const price = isPaint ? process.env.REACT_APP_COST_NFT_PAINT_DOLLARS : getPrintPrice();
      const body = {
        email,
        pid,
        staticVersion: productType === "static" ? staticVersions[version] : undefined,
        version,
        orderName,
        isPaint,
        postPrint,
        price,
        printSize,
        postage,
        packaging
      };
      //console.log("Order body: ", body);
      const sendOrderResponse = await fetch(utils.getEndPoint("order"), {
        method: "POST",
        body: JSON.stringify(body)
      });

      if (!sendOrderResponse.ok)
        throw Error(`'order' endpoint POST error. ${await sendOrderResponse.text()}`);

      console.log("DONE!");
      setNextState(CREATE_STATE.ORDER_PLACED);
    } catch (error) {
      onAlert({ title: <h2>Whoa!?</h2>, body: "That wasn't supposed to happen??! Panic!" });
      const entry = {
        type: "ERROR",
        description: "Sending Order",
        pid,
        version,
        email,
        error
      };
      console.log("Entry: ", entry);
      await utils.log(entry);
    } finally {
      setBusy(false);
    }
  };

  const handleCreateProduct = async ({ type, reCreate, useExistingSelfie }) => {
    try {
      setBusy(true);
      setProductUrlA(undefined);
      setProductUrlB(undefined);

      let products = undefined;

      if (type === "selfie") {
        products = await createSelfieProducts(pid, useExistingSelfie);
      }

      if (type === "static") {
        products = await createStaticProducts(pid);
      }

      const { urlA, urlB } = products;

      setProductUrlA(urlA);
      setProductUrlB(urlB);

      if (!useExistingSelfie && !reCreate) {
        setNextState(CREATE_STATE.PRODUCTS_CREATED);
      }

      // send approval email if not already sent
      try {
        if (!isProductApproved) {
          console.log("Sending approval email...");
          const sendApproveEmailResponse = await fetch(utils.getEndPoint("approve"), {
            method: "POST",
            body: JSON.stringify({ pid, productType, urlA, urlB })
          });

          if (!sendApproveEmailResponse.ok)
            throw Error(`'approve' endpoint POST error. ${await sendApproveEmailResponse.text()}`);
        }
        console.log("DONE! Sending approval email.");
      } catch (error) {
        const entry = {
          type: "ERROR",
          description: "Send Approved Email",
          pid,
          version,
          email,
          error
        };
        console.log("Entry: ", entry);
        await utils.log(entry);
      }
      //sendMintCode();
    } catch (error) {
      onAlert({
        title: <h2>Nooooo... something just ^%$#*@ broke!</h2>,
        body: "The minions are on it. Give it another shot?"
      });
      const entry = {
        type: "ERROR",
        description: "Create Product",
        pid,
        version,
        email,
        error
      };
      console.log("Entry: ", entry);
      await utils.log(entry);
    } finally {
      setBusy(false);
    }
  };

  const readFile = async (file, asBuffer) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      if (asBuffer) reader.readAsArrayBuffer(file);
      else reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      console.log("File type: ", file.type);
      setFile(file);
      setSelfieSrc(await readFile(file));
      setNextState(CREATE_STATE.CROP_SELFIE);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase().trim());
  };

  const handleOrderNameChange = (e) => {
    setOrderName(e.target.value);
  };

  const handleCheckEmail = async () => {
    try {
      if (email.length < 5) return;
      if (email.match(/^[\w-\.]+(\+?\w+)?@([\w-]+\.)+[\w-]{2,4}$/g) < 1) {
        onAlert({ title: <h2>Whoops!?</h2>, body: "Email format error." });
        return;
      }

      setBusy(true);
      setPid("");
      setMintedProduct(null);
      const response = await fetch(
        utils.getEndPoint("status", `email=${encodeURIComponent(email.toLowerCase())}`)
      );

      if (!response.ok) throw Error(`'status' endpoint GET error. ${await response.text()}`);

      const statusEntry = await response.json();
      console.log("Got status: ", statusEntry);
      const { pid: entryPid, minted, products, type, isApproved, order } = statusEntry;

      setProductType(type);
      //setIsProductApproved(isApproved);

      if (entryPid) {
        setPid(entryPid);
      }

      if (order) {
        if (type === "selfie") {
          setOrderUrl(products.find((p) => p.version === order.version).signedUrl);
        }
        if (type === "static") {
          setOrderUrl(products.find((p) => p.version === order.staticVersion).signedUrl);
        }
        setNextState(CREATE_STATE.PRODUCT_ALREADY_ORDERED);
        return;
      }

      if (minted) {
        console.log("Product minted: ", minted);
        setMintedProduct(minted);
        setTokenId(minted.tokenId);
        setNextState(CREATE_STATE.PRODUCT_ALREADY_MINTED);
        return;
      }

      if (products && products.length > 0 && !minted) {
        if (type === "static") {
          //console.log("Setting static versions: ", products[0].version, products[1].version);
          setStaticVersions({ A: products[0].version, B: products[1].version });
          setProductUrlA(products[0].signedUrl);
          setProductUrlB(products[1].signedUrl);
        }
        if (type === "selfie") {
          setStaticVersions();
          setProductUrlA(products.find((o) => o.version === "A").signedUrl);
          setProductUrlB(products.find((o) => o.version === "B").signedUrl);
        }
        setNextState(CREATE_STATE.PRODUCT_ALREADY_CREATED);
        return;
      }

      if (!products || !minted) {
        setNextState(CREATE_STATE.ENTER_PID);
      }
    } catch (error) {
      onAlert({ title: <h2>Waaaaaa! Can't check email</h2>, body: "Weird. Try again?" });
      const entry = {
        type: "ERROR",
        description: "Check Email",
        pid,
        version,
        email,
        error
      };
      console.log("Entry: ", entry);
      await utils.log(entry);
    } finally {
      setBusy(false);
    }
  };

  const handlePidChange = (e) => {
    setPid(e.target.value.trim());
  };

  const handleCheckPid = async () => {
    setBusy(true);
    const pidError = { title: <h2>Yikes!</h2> };
    try {
      if (pid.length < 5 || pid.length > 15) {
        pidError.body = "PID must be between 5 and 15 characters.";
        onAlert(pidError);
        return;
      }

      if (!/^[A-Za-z0-9_]*$/.test(pid)) {
        pidError.body = "PID contains invalid characters.";
        onAlert(pidError);
        return;
      }

      console.log("PID/Email: ", pid, email);
      const getPidEntryRequest = fetch(utils.getEndPoint("pid", `pid=${pid}`));
      const getStatusRequest = fetch(
        utils.getEndPoint("status", `email=${encodeURIComponent(email.toLowerCase())}`)
      );

      const [getPidEntryResponse, getStatusResponse] = await Promise.all([
        getPidEntryRequest,
        getStatusRequest
      ]);

      if (!getPidEntryResponse.ok) {
        // Returns custom messages as well as stock 'Internal Server Error'
        throw Error(`'pid' endpoint GET error. ${await getPidEntryResponse.text()}`);
      }

      const pidEntry = await getPidEntryResponse.json();
      console.log("Got pidEntry: ", pidEntry);

      if (!getStatusResponse.ok) {
        // Returns custom messages as well as stock 'Internal Server Error'
        throw Error(`'status' endpoint GET error. ${await getStatusResponse.text()}`);
      }

      const statusEntry = await getStatusResponse.json();
      console.log("Got statusEntry: ", statusEntry);

      if (pidEntry) {
        console.log(`PID ${pid} exists.`);
        if (pidEntry.email === email) {
          console.log(`PID ${pid} already belongs to ${pidEntry.email}.`);
          if (pidEntry.pid !== pid) {
            console.log(`Existing PID ${pidEntry.pid} different from ${pid} updating...`);
            const pidPutResponse = await fetch(utils.getEndPoint("pid"), {
              method: "PUT",
              body: JSON.stringify({ pid, email })
            });

            if (!pidPutResponse.ok) {
              throw Error(`'pid' endpoint PUT error. ${await pidPutResponse.text()}`);
            }
          } else {
            console.log("PID same for this email, skipping.");
          }

          setNextState(CREATE_STATE.SELECT_PRODUCT_TYPE);
          setBackState(CREATE_STATE.ENTER_EMAIL);
          return;
        } else {
          pidError.body = "PID is already in use.";
          onAlert(pidError);
          return;
        }
      }

      if (statusEntry.pid) {
        // email already has pid so update it
        console.log(`${email} already has pid updating to ${pid}`);
        const pidPutResponse = await fetch(utils.getEndPoint("pid"), {
          method: "PUT",
          body: JSON.stringify({ pid, email })
        });

        if (!pidPutResponse.ok) {
          throw Error(`'pid' endpoint PUT error. ${await pidPutResponse.text()}`);
        }
      } else {
        // no pid for email so must be new
        console.log(`No PID found for ${email}, adding new...`);
        const postResponse = await fetch(utils.getEndPoint("pid"), {
          method: "POST",
          body: JSON.stringify({ pid, email: email.toLowerCase() })
        });

        if (!postResponse.ok) {
          throw Error(`'pid' endpoint POST error. ${await postResponse.text()}`);
        }
      }

      setNextState(CREATE_STATE.SELECT_PRODUCT_TYPE);
      setBackState(CREATE_STATE.ENTER_EMAIL);
    } catch (error) {
      pidError.body =
        "Something went terribly wrong! Computer said no to creating a that PID. *shrug*";
      onAlert(pidError);
      const entry = {
        type: "ERROR",
        description: "Create PID",
        pid,
        version,
        email,
        error
      };
      console.log("Entry: ", entry);
      await utils.log(entry);
    } finally {
      setBusy(false);
    }
  };

  return (
    <>
      {!showProduct && (
        <div
          className="close-create"
          onClick={() => {
            resetVars();
            onClose();
          }}
        >
          X
        </div>
      )}
      {!busy && (
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            zIndex: 1,
            display: `${nextState === CREATE_STATE.CROP_SELFIE ? "flex" : "none"}`
          }}
        >
          <Button
            disabled={busy}
            clearMarginBottom
            style={{
              backgroundColor: "#ff006f"
            }}
            label="<"
            onClick={() => setNextState(CREATE_STATE.SELFIE_INSTRUCTIONS)}
          />
          <Button
            clearMarginBottom
            style={{
              backgroundColor: "#ff006f"
            }}
            busy={busy}
            label="Create"
            onClick={() => handleCreateProduct({ type: "selfie" })}
          />
          <label
            htmlFor="file-picker"
            className="button"
            style={{
              backgroundColor: "#ff006f",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <input
              disabled={busy ? "disabled" : ""}
              style={{ display: "none" }}
              id="file-picker"
              type="file"
              accept="image/*"
              capture="user"
              onChange={handleFileChange}
            />
            <img alt="Retake" src={camera} height={21} />
          </label>
        </div>
      )}
      {showProduct ? (
        <div
          className="product"
          style={{ backgroundImage: `url(${showProduct})`, height: "100%", justifyContent: "end" }}
          onClick={() => setShowProduct(undefined)}
        >
          <img alt="Zoom out" src="zoom-out.svg" width={40} height={40} />
        </div>
      ) : (
        <>
          <h2>
            CREATE
            <br />
            PRODUCT
          </h2>
          {nextState === CREATE_STATE.INTRO && (
            <div className="create-content">
              <div className="create-content-body">
                <span style={{ marginBottom: "10px" }}>
                  <h3>Welcome!</h3>
                </span>
                {window.isTouchDevice() ? (
                  <>
                    You are about to create a 'PRODUCT'. An interactively generated digital artwork;
                    a socially reconstructed selfie portrait embedded in the social context of the
                    times we live in.
                    <p>
                      I agree to the{" "}
                      <button
                        className="link-button"
                        style={{ color: "black", textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => {
                          onAlert({
                            title: (
                              <h2 style={{ textAlign: "left" }}>
                                THE PRODUCT TERMS &amp; CONDITIONS
                              </h2>
                            ),
                            style: { top: 0, textAlign: "left" },
                            body: Terms()
                          });
                          setTimeout(() => {
                            /* useRef instead? */
                            document.getElementById("overlay").scrollIntoView();
                          }, 500);
                        }}
                      >
                        T&amp;Cs
                      </button>
                      <label className="cb-container">
                        <input
                          type="checkbox"
                          onClick={(e) => setTermsAccepted(e.target.checked)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </p>
                    {/* <span style={{ width: "75%", maxWidth: "600px" }}>
                  <SubscribeForm />
                </span> */}
                    {/* <div className="product rotate">
                  <div className="product2 rotate2" />
                </div> */}
                  </>
                ) : (
                  <h3 className="noMobile">You can only create a product with a touch device.</h3>
                )}
              </div>
              <div style={{ textAlign: "center" }}>
                {termsAccepted && (
                  <>
                    <Button
                      clearMarginRight
                      label="CONTINUE"
                      onClick={() => {
                        setNextState(CREATE_STATE.ENTER_EMAIL);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.SELECT_PRODUCT_TYPE && (
            <div className="create-content">
              {busy ? (
                <div className="create-selfie-loader">
                  <img alt="" src={loader} width="50%" />
                </div>
              ) : (
                <>
                  <div className="create-content-body">
                    <span style={{ marginBottom: "10px" }}>
                      <h3>Choose Product Creation Type</h3>
                    </span>
                    <div>
                      You can create a Product from the existing Product database or you can create
                      a Product using your selfie.
                    </div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      disabled={busy}
                      onClick={() => {
                        setNextState(CREATE_STATE.ENTER_EMAIL);
                      }}
                      label="<"
                    />
                    <Button
                      busy={busy}
                      label="CREATE USING EXISTING"
                      onClick={() => {
                        setStaticVersions();
                        setProductType("static");
                        handleCreateProduct({ type: "static" });
                      }}
                    />
                    <Button
                      disabled={busy}
                      clearMarginRight
                      label="CREATE USING SELFIE"
                      onClick={() => {
                        setProductType("selfie");
                        setNextState(CREATE_STATE.SELECT_SM);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {nextState === CREATE_STATE.CONNECT_METAMASK && (
            <div className="create-content">
              <ConnectWallet
                onCancel={() => {
                  setSelectedAccount();
                  setNextState(CREATE_STATE.VIEW_PRODUCTS);
                }}
                onAccountChange={(account) => {
                  setSelectedAccount(account);
                }}
                onContinue={() => setNextState(CREATE_STATE.CONFIRM_MINT)}
              />
            </div>
          )}
          {nextState === CREATE_STATE.ENTER_EMAIL && (
            <div className="create-content">
              <div className="create-content-body">
                <p>
                  Enter your email and we will retrieve your product information. If none is found,
                  a new product will be associated with this email.
                </p>
                <input
                  type="text"
                  placeholder="Enter email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <div>
                <Button
                  disabled={busy}
                  onClick={() => {
                    setTermsAccepted(false);
                    setNextState(CREATE_STATE.INTRO);
                  }}
                  label="<"
                />
                <Button clearMarginRight busy={busy} label="Continue" onClick={handleCheckEmail} />
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.ENTER_PID && (
            <div className="create-content">
              <div className="create-content-body">
                <p>Enter a product identifier (PID). This is a unique name for your product.</p>
                <input type="text" placeholder="Enter PID" value={pid} onChange={handlePidChange} />
                <p>PID must be between 5-15 characters. Valid characters are Aa to Zz 0 to 9 _</p>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setPid("");
                    setNextState(CREATE_STATE.ENTER_EMAIL);
                  }}
                  label="<"
                />
                <Button busy={busy} clearMarginRight label={"Continue"} onClick={handleCheckPid} />
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.PRODUCTS_CREATED && (
            <div className="create-content">
              <div className="create-content-body">
                <h3>SUCCESS!</h3>
                <span>
                  <p>
                    Two Products A &amp; B have been created and given the PID{" "}
                    <strong>{pid}</strong>.{" "}
                  </p>
                  {productType === "selfie" && (
                    <>
                      Product A has your selfie as the base. Product B has a random part from your
                      selfie.
                    </>
                  )}
                  <p>
                    <small>
                      NOTE: Products are compressed &amp; watermarked. The final Product will be
                      higher quality and have the watermarked removed.
                    </small>
                  </p>
                </span>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setSsmChoices([]);
                    setSsmNone(false);
                    setNextState(CREATE_STATE.ENTER_EMAIL);
                  }}
                  label="<"
                />
                <Button
                  clearMarginRight
                  label="View Products"
                  onClick={() => setNextState(CREATE_STATE.VIEW_PRODUCTS)}
                />
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.PRODUCT_ALREADY_CREATED && (
            <div className="create-content">
              <div className="create-content-body">
                <p>
                  There are Products already created with PID '<strong>{pid}</strong>'.
                </p>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  disabled={busy}
                  onClick={() => {
                    //setMintCodeSent(false);
                    //setMintCode("");
                    setSsmChoices([]);
                    setSsmNone(false);
                    setNextState(CREATE_STATE.ENTER_EMAIL);
                  }}
                  label="<"
                />
                <Button
                  busy={busy}
                  label="View Products"
                  onClick={() => setNextState(CREATE_STATE.VIEW_PRODUCTS)}
                />
                {/* <Button
                  busy={busy}
                  label="Create New"
                  clearMarginRight
                  onClick={() => {
                    // Don't allow to enter new pid otherwise existing products will be orphaned!
                    setSsmChoices([]);
                    setSsmNone(false);
                    setNextState(CREATE_STATE.SELECT_PRODUCT_TYPE);
                    setBackState(CREATE_STATE.PRODUCT_ALREADY_CREATED);
                  }}
                /> */}
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.VIEW_PRODUCTS && (
            <div className="create-content">
              <strong>{pid}</strong>
              <div className="create-content-body" style={{ flexDirection: "unset" }}>
                <div className="product-content">
                  <div
                    className="product"
                    onClick={() => setShowProduct(productUrlA)}
                    style={{
                      backgroundImage: `url(${busy ? loader : productUrlA})`,
                      width: `${busy ? "50%" : "100%"}`
                    }}
                  />
                  <div className="zoom" onClick={() => setShowProduct(productUrlA)}>
                    A
                  </div>
                </div>
                <div className="product-content">
                  <>
                    <div
                      className="product"
                      onClick={() => setShowProduct(productUrlB)}
                      style={{
                        backgroundImage: `url(${busy ? loader : productUrlB})`,
                        width: `${busy ? "50%" : "100%"}`
                      }}
                    />
                    <div className="zoom" onClick={() => setShowProduct(productUrlB)}>
                      B
                    </div>
                  </>
                </div>
              </div>
              <div className="create-nav">
                <Button
                  disabled={busy}
                  onClick={() => {
                    setVersion();
                    setNextState(CREATE_STATE.SELECT_PRODUCT_TYPE);
                  }}
                  label="<"
                />
                <Button
                  disabled={busy}
                  label="Choose A"
                  onClick={() => {
                    setVersion("A");
                    setNextState(CREATE_STATE.ORDER);
                  }}
                />
                <Button
                  disabled={busy}
                  label="Choose B"
                  onClick={() => {
                    setVersion("B");
                    setNextState(CREATE_STATE.ORDER);
                  }}
                />
                <Button
                  busy={busy}
                  imgSrc={redo}
                  imgAlt="Create again"
                  onClick={() =>
                    handleCreateProduct({
                      type: productType,
                      useExistingSelfie: productType === "selfie",
                      reCreate: true
                    })
                  }
                />
                {productType === "selfie" && (
                  <Button
                    clearMarginRight
                    disabled={busy}
                    imgSrc={camera}
                    imgAlt="Re-take selfie"
                    onClick={() => {
                      setNextState(CREATE_STATE.SELFIE_INSTRUCTIONS);
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.PRODUCT_ALREADY_ORDERED && (
            <div className="create-content">
              <div className="create-content-body" style={{ flexDirection: "unset" }}>
                <div className="product-content">
                  <p>
                    You have already ordered the product <strong>{pid}</strong>
                  </p>
                  <div
                    className="product"
                    onClick={() => setShowProduct(orderUrl)}
                    style={{ backgroundImage: `url(${orderUrl})` }}
                  />
                  <div
                    className="zoom"
                    style={{ width: "40px" }}
                    onClick={() => setShowProduct(orderUrl)}
                  ></div>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  clearMarginRight
                  disabled={busy}
                  onClick={() => {
                    setNextState(CREATE_STATE.ENTER_EMAIL);
                  }}
                  label="<"
                />
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.PRODUCT_ALREADY_MINTED && (
            <div className="create-content">
              <div className="create-content-body">
                <div>{mintedProduct && <MintedProduct minted={mintedProduct} />}</div>
                <p>
                  <button
                    className="link-button"
                    onClick={() => {
                      setNextState(CREATE_STATE.IMPORT_TO_MM);
                      setBackState(CREATE_STATE.PRODUCT_ALREADY_MINTED);
                    }}
                  >
                    Import into MetaMask
                  </button>
                </p>
              </div>
              <div>
                <Button
                  clearMarginRight
                  label="<"
                  onClick={() => {
                    setNextState(CREATE_STATE.ENTER_EMAIL);
                  }}
                />
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.SELECT_SM && (
            <div className="create-content">
              <div className="create-content-body">
                <div>
                  <p>Select the Social Media your engage with.</p>
                  <p>
                    <small>
                      Used for only image composition. There is no engagement with these platforms
                      whatsoever.
                    </small>
                  </p>
                  <Button
                    className="sm-button"
                    label="None"
                    onClick={() => {
                      setSsmChoices([]);
                      setSsmNone((o) => !o);
                    }}
                    style={{
                      backgroundColor: ssmNone ? "white" : "unset"
                    }}
                  />
                  <Button
                    className="sm-button"
                    label="Facebook"
                    onClick={() => handleSsmChoice("meat")}
                    style={{
                      backgroundColor: ssmChoices.indexOf("meat") > -1 ? "white" : "unset"
                    }}
                  />
                  <Button
                    className="sm-button"
                    label="YouTube"
                    onClick={() => handleSsmChoice("utuobey")}
                    style={{
                      backgroundColor: ssmChoices.indexOf("utuobey") > -1 ? "white" : "unset"
                    }}
                  />
                  <Button
                    className="sm-button"
                    label="Instagram"
                    onClick={() => handleSsmChoice("grimsatan")}
                    style={{
                      backgroundColor: ssmChoices.indexOf("grimsatan") > -1 ? "white" : "unset"
                    }}
                  />
                  <Button
                    className="sm-button"
                    label="Linked In"
                    onClick={() => handleSsmChoice("delinkin")}
                    style={{
                      backgroundColor: ssmChoices.indexOf("delinkin") > -1 ? "white" : "unset"
                    }}
                  />
                  <Button
                    className="sm-button"
                    label="Spotify"
                    onClick={() => handleSsmChoice("fitposy")}
                    style={{
                      backgroundColor: ssmChoices.indexOf("fitposy") > -1 ? "white" : "unset"
                    }}
                  />
                  <Button
                    className="sm-button"
                    label="Twitter"
                    onClick={() => handleSsmChoice("titwert")}
                    style={{
                      backgroundColor: ssmChoices.indexOf("titwert") > -1 ? "white" : "unset"
                    }}
                  />
                  <Button
                    className="sm-button"
                    label="WhatsApp"
                    onClick={() => handleSsmChoice("swappath")}
                    style={{
                      backgroundColor: ssmChoices.indexOf("swappath") > -1 ? "white" : "unset"
                    }}
                  />
                  <Button
                    className="sm-button"
                    label="Snapchat"
                    onClick={() => handleSsmChoice("pantcash")}
                    style={{
                      backgroundColor: ssmChoices.indexOf("pantcash") > -1 ? "white" : "unset"
                    }}
                  />
                  <Button
                    className="sm-button"
                    label="Tiktok"
                    onClick={() => handleSsmChoice("titkok")}
                    style={{
                      backgroundColor: ssmChoices.indexOf("titkok") > -1 ? "white" : "unset"
                    }}
                  />
                </div>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setSsmNone(false);
                    setSsmChoices([]);
                    setNextState(backState);
                  }}
                  label="<"
                />
                {(ssmNone || ssmChoices.length > 0) && (
                  <Button
                    clearMarginRight
                    label="Continue"
                    onClick={() => setNextState(CREATE_STATE.SELFIE_INSTRUCTIONS)}
                  />
                )}
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.SELFIE_INSTRUCTIONS && (
            <div className="create-content">
              <div className="create-content-body">
                <div>
                  <p>
                    Take the selfie straight on with your head level, as if you were taking a
                    passport photo. If you wear glasses, try with and without them. If it's blurry
                    retake it!
                  </p>
                  Pinch, rotate &amp; zoom to adjust the selfie so your eyes, nose and mouth line up
                  with the template.
                </div>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setSelfieSrc(null);
                    setNextState(CREATE_STATE.SELECT_SM);
                  }}
                  label="<"
                />
                <Button
                  clearMarginRight
                  busy={busy}
                  label="Take Selfie"
                  onClick={() => {
                    /* useRef instead */
                    document.getElementById("file-picker").click();
                  }}
                />
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.CROP_SELFIE && selfieSrc && (
            <>
              {busy ? (
                <div className="create-selfie-loader">
                  <img alt="" src={loader} width="50%" />
                </div>
              ) : (
                <SelfieCropper
                  disable={busy}
                  imageSrc={selfieSrc}
                  onAreaCropped={(info) => {
                    setCropInfo(info);
                  }}
                />
              )}
            </>
          )}
          {nextState === CREATE_STATE.SUCCESS && (
            <div className="create-content">
              <div className="create-content-body">
                <h2>Success :)</h2>
                <div>
                  Your Product <strong>{pid}</strong> has been minted. It may take a few minutes for
                  it to appear on OpenSea.
                  <p>
                    <button
                      className="link-button"
                      onClick={() => {
                        setNextState(CREATE_STATE.IMPORT_TO_MM);
                        setBackState(CREATE_STATE.SUCCESS);
                      }}
                    >
                      Import into MetaMask
                    </button>
                  </p>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={() => setNextState(CREATE_STATE.SHOW_CREDITS, CREATE_STATE.SUCCESS)}
                  label="?"
                />
                <Button
                  onClick={() => window.open(openSeaNftUrl, "_blank")}
                  label={<>View on OpenSea</>}
                />
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.IMPORT_TO_MM && (
            <div className="create-content">
              <div className="create-content-body">
                <ImportIntoMetaMask contractAddress={CONTRACT_ADDRESS} tokenId={tokenId} />
              </div>
              <div>
                <Button clearMarginRight onClick={() => setNextState(backState)} label="<" />
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.SHOW_CREDITS && (
            <div className="create-content">
              <div className="create-content-body">
                <p>
                  Created by{" "}
                  <a href="https://www.melm.nz" target={"_blank"} rel="noopener">
                    MELM
                  </a>
                </p>
                <img className="melm" alt="MELM" src={melm} style={{ maxWidth: "600px" }} />
                <p>&copy; {new Date().getFullYear()}</p>
              </div>
              <div>
                <Button onClick={() => setNextState(CREATE_STATE.SUCCESS)} label="<" />
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.ORDER && (
            <div className="create-content">
              <div style={{ textAlign: "center" }}>
                You chose product version <strong>{version}. </strong> What would you like?
              </div>
              <div className="create-content-body" style={{ flexDirection: "unset" }}>
                <div className="product-content">
                  <div
                    className="product"
                    style={{
                      backgroundImage: `url(${version === "A" ? productUrlA : productUrlB})`
                    }}
                  />
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  disabled={busy}
                  onClick={() => {
                    setVersion();
                    setOrderName("");
                    setNextState(CREATE_STATE.VIEW_PRODUCTS);
                  }}
                  label="<"
                />
                <Button
                  busy={busy}
                  onClick={() => setNextState(CREATE_STATE.CONNECT_METAMASK)}
                  label={<>NFT</>}
                />
                <Button
                  busy={busy}
                  onClick={() => setNextState(CREATE_STATE.ORDER_PRINT)}
                  label={<>PRINT + NFT</>}
                />
                <Button
                  clearMarginRight
                  busy={busy}
                  onClick={() => setNextState(CREATE_STATE.ORDER_PAINT)}
                  label={<>PRINT + PAINT + NFT</>}
                />{" "}
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.ORDER_PRINT && (
            <div className="create-content">
              <div className="create-content-body">
                <p>
                  Print is signed, dated, numbered and labeled with your PID. Once you place the
                  order, you will be emailed payment instructions.
                </p>
                <input
                  type="text"
                  placeholder="Please enter your first &amp; last name."
                  value={orderName}
                  onChange={handleOrderNameChange}
                />
                <div style={{ textAlign: "left" }}>
                  <p>
                    <strong>
                      A1 <small>594 x 841mm.</small> $
                      {process.env.REACT_APP_COST_NFT_PRINT_DOLLARS_A1}
                    </strong>
                    <label className="cb-container">
                      <input
                        type="radio"
                        name="print-size"
                        defaultChecked
                        onClick={(e) => setPrintSize("A1")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </p>
                  <p>
                    <strong>
                      A2 <small>420 x 594mm.</small> $
                      {process.env.REACT_APP_COST_NFT_PRINT_DOLLARS_A2}
                    </strong>
                    <label className="cb-container">
                      <input type="radio" name="print-size" onClick={(e) => setPrintSize("A2")} />
                      <span className="checkmark"></span>
                    </label>
                  </p>
                  <p>
                    <strong>
                      A3 <small>297 x 420mm.</small> $
                      {process.env.REACT_APP_COST_NFT_PRINT_DOLLARS_A3}
                    </strong>
                    <label className="cb-container">
                      <input type="radio" name="print-size" onClick={(e) => setPrintSize("A3")} />
                      <span className="checkmark"></span>
                    </label>
                  </p>
                  <small>
                    Post (Aotearoa NZ only). + $
                    {(
                      Number(process.env.REACT_APP_COST_NFT_PRINT_PACKAGING) +
                      Number(process.env.REACT_APP_COST_NFT_PRINT_POSTAGE)
                    ).toFixed(2)}
                  </small>
                  <label className="cb-container">
                    <input type="checkbox" onClick={(e) => setPostPrint(e.target.checked)} />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div>
                <Button
                  disabled={busy}
                  onClick={() => {
                    setNextState(CREATE_STATE.ORDER);
                  }}
                  label="<"
                />
                {orderName && (
                  <Button busy={busy} onClick={() => handlePlaceOrder()} label="Place Order" />
                )}
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.ORDER_PAINT && (
            <div className="create-content">
              <div className="create-content-body">
                <p>
                  Paintings are acrylic on canvas and have a custom built frame. 795mm x 1050mm.
                  Also included is an A1 signed print and NFT.
                </p>
                <p>
                  <strong>
                    ${process.env.REACT_APP_COST_NFT_PAINT_DOLLARS}
                    <small> NZD</small>
                  </strong>
                </p>
                <p>
                  <small>
                    Please enter your name (first &amp; last) and I will send you an email.
                  </small>
                </p>
                <input type="text" value={orderName} onChange={handleOrderNameChange} />
              </div>
              <div>
                <Button
                  disabled={busy}
                  onClick={() => {
                    setNextState(CREATE_STATE.ORDER);
                  }}
                  label="<"
                />
                {orderName && (
                  <Button
                    busy={busy}
                    onClick={() => handlePlaceOrder(/*isPaint*/ true)}
                    label="ENQUIRE"
                  />
                )}
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.ORDER_PLACED && (
            <div className="create-content">
              <div className="create-content-body">
                <p>Thanks! I'll get onto it asap.</p>
                <p>
                  If you'd like to view your product in the mean time. Select --create from the
                  menu, enter your email and your product will be loaded.
                </p>
              </div>
              <div>
                <Button
                  onClick={() => {
                    resetVars();
                    onClose();
                  }}
                  label="Close"
                />
              </div>
            </div>
          )}
          {nextState === CREATE_STATE.CONFIRM_MINT && (
            <div className="create-content">
              <div>
                Mint product <strong>{pid} </strong>version <strong>{version}</strong>?
              </div>
              <div className="create-content-body" style={{ flexDirection: "unset" }}>
                <div className="product-content">
                  <div
                    className="product"
                    style={{
                      backgroundImage: `url(${version === "A" ? productUrlA : productUrlB})`
                    }}
                  />
                </div>
              </div>
              <div>
                <Button
                  disabled={busy}
                  onClick={() => {
                    setVersion();
                    setNextState(CREATE_STATE.VIEW_PRODUCTS);
                  }}
                  label="<"
                />
                <Button busy={busy} onClick={() => handleMint(version)} label="Mint" />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

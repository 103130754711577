export default function Terms() {
  return (
    <>
      <p>
        THE PRODUCT is a collection of digital artworks (NFTs) running on the Polygon network
        (blockchain). Users are entirely responsible for the safety and management of their own
        digital wallets and validating all transactions and contracts. Furthermore, as THE PRODUCT
        Smart Contract runs on the blockchain, there is no ability to undo, reverse, or restore any
        transactions.
      </p>
      <p>
        When you own/purchase a PRODUCT NFT, you own the underlying digital artwork, completely.
        Ownership of the NFT is mediated entirely by the Smart Contract and the Polygon Network.
      </p>
      <p>
        When purchasing a PRODUCT print or painting, the NFT will be minted to an intermediatory
        wallet and you will have upto <strong>12 weeks</strong> to request a transfer of the NFT to
        your wallet, after the transfer period has expired the NFT can be sold on an open
        marketplace. Until the NFT is transferred or the claim period expires (which ever comes
        first), the ownership of the NFT is linked solely to the email address that was used to
        purchase the artwork. Therefore, a request to transfer the NFT can only be made from that
        specific email address.
      </p>
      <p>
        In the case of a marketplace where it permits the purchase and sale of the NFT, the
        marketplace must cryptographically verify each PRODUCT's owner's rights to display the
        artwork to ensure that only the actual owner can display or sell the artwork.
      </p>
      <p>
        When ordering a print you understand that whilst you own the print, the ownership of the
        digital artwork itself is solely mediated by the ownership of the NFT.
      </p>
      <p>
        You understand that by purchasing a PRODUCT NFT, the image &amp; metadata associated with
        the Smart Contract will be viewable publicly. Your selfie will{" "}
        <em>
          <strong>NOT</strong>
        </em>{" "}
        be displayed in public nor sold or released to 3rd parties.
      </p>
      <p>
        This website and its connected services are provided "as is" and "as available" without
        warranty of any kind. By using this website you are accepting sole responsibility for any
        and all transactions involving THE PRODUCT digital artworks.
      </p>
    </>
  );
}

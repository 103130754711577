import "./scroll-down.css";
import React, { useEffect, useState } from "react";
import { Nav } from "./Nav";
import melm from "./melm.svg";
import AccordionFaqGeneral from "./AccordionFaqGeneral";
import AccordionFaqNfts from "./AccordionFaqNtfs";
import Engage from "./Engage";
import Create from "./Create";
import Alert from "./Alert";
import Exhibition from "./Exhibition";

function App() {
  const ALERT_DEFAULTS = { title: "", body: undefined };

  const [offset, setOffset] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [alert, setAlert] = useState(ALERT_DEFAULTS);
  const [showCreate, setShowCreate] = useState(window.location.search.indexOf("create") > -1);
  const [showExhibition, setShowExhibition] = useState(false);
  const [showMain, setShowMain] = useState(true);

  useEffect(() => {
    const onScroll = () => {
      setOffset(window.pageYOffset);
      setShowMenu(false);
    };
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleNavItemClick = (item) => {
    switch (item) {
      default:
        break;
      case "create":
        setShowCreate(true);
        setTimeout(() => setShowMain(false), 500); // slide in transition;
        break;
      case "opensea":
        window.open(process.env.REACT_APP_OPENSEA_COLLECTION_URL, "_blank");
        break;
      case "exhibition":
        setShowExhibition(true);
        setTimeout(() => {
          setShowMain(false);
          document.getElementById("exhibition").scrollIntoView();
        }, 1000); // slide in transition;
        break;
    }
  };

  return (
    <>
      <Alert {...alert} onClose={() => setAlert(ALERT_DEFAULTS)} />
      <div className="create-overlay" style={{ left: showCreate ? 0 : "100%" }}>
        <Create
          onAlert={(e) => setAlert(e)}
          onClose={() => {
            setShowMain(true);
            setShowCreate(false);
          }}
        />
      </div>
      <div
        className="create-overlay"
        style={{
          left: showExhibition ? 0 : "100%",
          overflowX: "auto",
          display: "block",
          backgroundColor: "#1ec0ae"
        }}
      >
        <Exhibition
          show={showExhibition}
          onClose={() => {
            setShowMain(true);
            setShowExhibition(false);
          }}
        />
      </div>
      {offset === 0 && !showCreate && !showExhibition && <div className="scroll-down"></div>}
      {offset > 50 && !showCreate && !showExhibition && (
        <Nav
          showMenu={showMenu}
          onClick={() => setShowMenu((s) => !s)}
          onClose={() => setShowMenu(false)}
          onNavItemClick={(e) => handleNavItemClick(e)}
        />
      )}
      {showMain && (
        <>
          <div>
            <div className="section title">
              <div className="fixed">
                <h1>THE PRODUCT</h1>
              </div>
            </div>
            <div className="section text-a">
              <div className="fixed">
                <p>Familiar&nbsp;or&nbsp;Freaks</p>
                <p>&mdash;</p>
                <p>Diversity&nbsp;or&nbsp;Monstrosity</p>
                <p>&mdash;</p>
                <p>Inclusion&nbsp;or&nbsp;Exclusion</p>
                <p>&mdash;</p>
                <p>Trust&nbsp;or&nbsp;Disgust</p>
              </div>
            </div>
            <div className="section gif" />
            <div className="section text-b">
              <div className="fixed">
                THE PRODUCT is a collection of 1000 interactively generated digital artworks;
                socially reconstructed selfie portraits embedded in the social context of the times
                we live in.
                <p>&mdash;</p>
                Inspired by Social&nbsp;Constructionist theory and the
                'The&nbsp;Social&nbsp;Dilemma'.
                <p>&mdash;</p>
                Influenced by the style and spirit of the DADA art movement.
              </div>
            </div>
            <div className="section text-c">
              <div className="fixed">
                The personas in the artworks share an interpretation of reality which is
                artificially constructed.
              </div>
            </div>
            <div className="img-a section">
              <div className="fixed"></div>
            </div>
            <div className="section text-d">
              <div className="fixed">
                Having been born into an age of surveillance capitalism, they have been mined &amp;
                manipulated.
              </div>
            </div>
            <div className="section text-e">
              <div className="fixed">Socially approved. Socially engineered. Socially branded.</div>
            </div>
            <div className="img-b section">
              <div className="fixed"></div>
            </div>
            <div className="section text-f">
              <div className="fixed">
                Consumables for the insentient prediction machines to be bought and sold, they are…
              </div>
            </div>
            <div className="section text-g">
              <div className="fixed">
                <h1>The Product</h1>
              </div>
            </div>
            <div id="demo" className="section demo-1">
              <div className="fixed" />
            </div>
            <div className="section demo-2">
              <div className="fixed" />
            </div>
            <div className="section demo-3">
              <div className="fixed" />
            </div>
            <div className="section demo-4">
              <div className="fixed" />
            </div>
            <div className="section create">
              <div className="fixed">
                <button
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "white",
                    borderColor: "black",
                    padding: "10px"
                  }}
                  onClick={() => setShowCreate(true)}
                >
                  create
                  <br />
                  product
                </button>
              </div>
            </div>
          </div>
          <div id="faq" className="section-faq">
            <div className="header-clip" style={{ backgroundColor: "#ffcf00" }}></div>
            <div style={{ backgroundColor: "#ffcf00", padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <div style={{ maxWidth: "600px" }}>
                  <h1 style={{ margin: 0, textAlign: "center", fontSize: "2.5em" }}>FAQ</h1>
                  <div className="divider">GENERAL</div>
                  <AccordionFaqGeneral />
                  <div className="divider">NFTs</div>
                  <AccordionFaqNfts />
                </div>
              </div>
            </div>
            <div id="engage" style={{ backgroundColor: "#ffcf00" }}>
              <div className="header-clip" style={{ backgroundColor: "#1ec0ae" }}></div>
            </div>
            <Engage bgColor="#1ec0ae" />
            <div id="footer" style={{ backgroundColor: "#1ec0ae" }}>
              <div className="header-clip" style={{ backgroundColor: "#1ec0ae" }}></div>
            </div>
            <div className="footer">
              <a href="https://www.melm.nz" target={"_blank"} rel="noopener">
                <img className="melm" src={melm} />
              </a>
              <p>&copy; MELM {new Date().getFullYear()}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default App;

import iconUtuObey from "./icon-utuobey.png";
import iconGrimSatan from "./icon-grimsatan.png";
import iconFitPosy from "./icon-fitposy.png";
import iconPantCash from "./icon-pantcash.png";
import iconMeat from "./icon-meat.png";
import iconTitWert from "./icon-titwert.png";
import iconSwapPath from "./icon-swappath.png";
import iconTitKok from "./icon-titkok.png";
import iconDelinkIn from "./icon-delinkin.png";
import Accordion, { AccordionSection } from "./Accordion";

export default function AccordionFaqGeneral() {
  return (
    <Accordion id="faq-general">
      <AccordionSection
        title="What are 'PRODUCTS'?"
        body={
          <>
            <p>
              THE PRODUCT is a collection of 1000 interactively generated digital artworks. It
              explores themes of social engineering, behavioral manipulation and surveillance
              capitalism; how we construct our reality that drives our behavior through the sharing
              of information, and the propagation of disinformation.
            </p>
            You create a Product by either taking a selfie, or randomly having one selected from the
            existing pool of Products. It is then deconstructed and reconstructed with other
            'Products'. The Product is then socially branded, resulting in two Products A &amp; B.
            Which will you choose? Trust or disgust? Freaky or familiar? Monstrosity or diversity?
            You decide. Regardless of your choice, your Product is 100% unique.
            <p>
              Products are reconstructed from a pool, and include the social media engagement, so
              when you see social media branding on a Product, it is inherited from its owner;
              knowledge and social action go together.
            </p>
            There are 26 Initial Products that have been generated from 26 base selfies + mine. As
            more 'selfie' Products are generated, and deconstructed, the diversity grows.
            <p>
              Because these are digital artworks, they each have an NFT.{" "}
              <strong>You can simply think of an NFT as a digital certificate of ownership</strong>.
              So when you purchase a print or painting it also includes the NFT.
            </p>
            Your Product is a selfie-portrait embedded in the social context of the times we live
            in.
          </>
        }
      />
      <AccordionSection
        title="How much do they cost?"
        body={
          <>
            <p>
              &mdash; <strong>NFT</strong>: {process.env.REACT_APP_COST_NFT_MATIC}{" "}
              {process.env.REACT_APP_CHAIN_SYMBOL}. Approx ${process.env.REACT_APP_COST_NFT_DOLLARS}
              .
            </p>
            &mdash; <strong>PRINT</strong>:
            <blockquote>
              &bull; A1 - ${process.env.REACT_APP_COST_NFT_PRINT_DOLLARS_A1}
              <br />
              &bull; A2 - ${process.env.REACT_APP_COST_NFT_PRINT_DOLLARS_A2}
              <br />
              &bull; A3 - ${process.env.REACT_APP_COST_NFT_PRINT_DOLLARS_A3}
            </blockquote>
            <p>
              &mdash; <strong>PAINT</strong>: ${process.env.REACT_APP_COST_NFT_PAINT_DOLLARS}
            </p>
            <p>
              <small>
                NOTE: Prices NZD. Prints are signed &amp; dated and are only available for postage
                in Aotearoa NZ.
              </small>
            </p>
          </>
        }
      />
      <AccordionSection
        title="How do I create a Digital Wallet?"
        body={
          <>
            <ol style={{ paddingInlineStart: "16px" }}>
              <li>
                <strong>Install the MetaMask App</strong>
                <br />
                There are many types of digital wallets, but the one used for this application is{" "}
                <a href="https://www.metamask.io" target={"_blank"} rel="noopener">
                  METAMASK
                </a>
                . Click on the link to go to their website and download the app for your device.
                Once installed, open the app & follow the instructions to set it up.
              </li>
              <br />
              <li>
                <strong>Browse to THE PRODUCT dApp</strong>
                <br />
                Open MetaMask and tap Browser at the bottom of the screen. Enter
                www.theproduct.social in the location bar. Create or load in your existing Products
                by tapping on &#9776; and selecting --create. Choose your product A or B, select
                NFT. You will then be prompted to Connect and Switch to the Polygon network.
                Approve/Agree. From there you should see 'Wallet ABC does not have have enough
                funds...', proceed to step 3. below.
              </li>
              <br />
              <li>
                <strong>Add some MATIC</strong>
                <br />
                <p>
                  You can either ask a friend to transfer you some MATIC (and pay them) or buy it
                  using the method below.
                </p>
                Once you have added the Polygon network and are connected to it. Tap on{" "}
                <strong>Wallet</strong> at the bottom of the screen. Then tap on '
                <strong>Buy</strong>' icon in the middle of the screen. You will then be prompted to
                select a purchase method. Choose whichever method your prefer, and enter the amount
                you would like to purchase, keeping in mind the purchase price of one NFT is{" "}
                {process.env.REACT_APP_COST_NFT_MATIC} {process.env.REACT_APP_CHAIN_SYMBOL} approx $
                {process.env.REACT_APP_COST_NFT_DOLLARS} NZD.
              </li>
              <br />
              <li>
                <strong>Time to MINT</strong>
                <br />
                That's it! You should now be able to mint. If you are still on the 'Wallet ABC does
                not have enough funds...' screen, tap the <strong>&lt;</strong> button, then choose
                your Product again.
              </li>
            </ol>
          </>
        }
      />
      <AccordionSection
        title="What is the 'Social Dilemma?"
        body={
          <>
            Visit this{" "}
            <a href="https://www.thesocialdilemma.com" target={"_blank"} rel="noopener">
              The Social Dilemma
            </a>{" "}
            for more info.
          </>
        }
      />
      <AccordionSection
        title="Why so much hating on Social Media?"
        body={
          <>
            <p>
              "When I criticize the system, they think I criticize them - and that is of course
              because they fully accept the system and identify themselves with it." - Thomas
              Merton.
            </p>
            If we use the system with our eyes shut, we are more likely to be eroded by it. However,
            if we are at least cognizant that the system has some control over us, we can then
            accept that sometimes our opinions and actions have been manipulated. If that is true of
            me, it will be true of others, and next time when we decide to put on our judgy or angry
            pants we may feel that they no longer fit.
            <p>
              There's definitely some irony with what's happening in the NFT space atm, but if you
              haven't done so already, you should read the NFT section below for my opinion on the
              subject. If the tech is used with integrity there are real benefits for creatives.
            </p>
          </>
        }
      />
      <AccordionSection
        title="How are original are they?"
        body="They are as unique as you are. No two products are the same."
      />
      <AccordionSection
        title="Will I be part of other 'products'?"
        body={
          <>
            <p>
              If you create a selfie Product, and its added to the pool, then yes. Your reality is
              constructed via your interactions with others.
            </p>
            <small>
              <strong>
                NOTE: Most selfies are not added to the pool. This is because, sometimes the selfie
                is blurry, wrong size or not a selfie at all. Occasionally I will add one if I feel
                it adds something aesthetically to the collection.
              </strong>
            </small>
          </>
        }
      />
      <AccordionSection
        title="Can I be recognized?"
        body={
          <>
            <em>Extremely unlikely</em>. You will be barely recognizable to yourself.
          </>
        }
      />
      <AccordionSection
        title="What happens to my selfie once it's uploaded to the server?"
        body={
          <>
            It's digitally deconstructed and used for the creation of your Products. It will not be
            displayed as is, nor will it be shared with third parties. If I choose to add your
            selfie to the pool, it will be used in the creation of other Products.
          </>
        }
      />
      <AccordionSection
        title="What Social Media brands are used?"
        body={
          <>
            The Social Media brands are parodies of major Social Media brands, with surveillance
            capitalism motifs tied in.
            <div style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
              <table>
                <thead>
                  <tr>
                    <td align="center" style={{ borderBottom: "3px double #ffcf00" }}>
                      <strong>Brand</strong>
                    </td>
                    <td align="center" style={{ borderBottom: "3px double #ffcf00" }}>
                      <strong>Name</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td align="center">
                      <img src={iconUtuObey} />
                    </td>
                    <td align="center">U Tu Obey</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img src={iconGrimSatan} />
                    </td>
                    <td align="center">Grim Satan</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img src={iconFitPosy} />
                    </td>
                    <td align="center">Fit Posy</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img src={iconMeat} />
                    </td>
                    <td align="center">Meat</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img src={iconPantCash} />
                    </td>
                    <td align="center">Pant Cash</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img src={iconTitWert} />
                    </td>
                    <td align="center">Tit Wert</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img src={iconSwapPath} />
                    </td>
                    <td align="center">Swap Path</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img src={iconTitKok} />
                    </td>
                    <td align="center">Tit Kok</td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img src={iconDelinkIn} />
                    </td>
                    <td align="center">Delink In</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        }
      />
      <AccordionSection
        title="Why can't I see my deconstructed selfie in other products?"
        body={
          <>
            Not every selfie gets admitted to the reconstruction pool. To maintain a certain
            aesthetic, I choose the ones that are most complementary.
          </>
        }
      />
      <AccordionSection
        title="What about copyright?"
        body={
          <>
            Even though you buy the work the copyright remains with the creator.
            <p>
              You have the right show the NFT or Print wherever you like (even in public), enjoy it
              and even resell it. But you don't own the right to copy it by either getting prints
              made of it, or taking a photo of it and selling the photo. That right continues to be
              owned by the artist.
            </p>
          </>
        }
      />
      <AccordionSection
        title="Are 'PRODUCTS' created by A.I?"
        body={<>No. I wrote the code myself.</>}
      />
      <AccordionSection
        title="Who am I?"
        body={
          <>
            Creative Weirdian from Aotearoa NZ. Take a look{" "}
            <a href="https://www.melm.nz" target={"_blank"}>
              at my site
            </a>{" "}
            for a humorous narrative about my creative family and this project.
          </>
        }
      />
    </Accordion>
  );
}

export function MintedProduct({ minted }) {
  const url = `${process.env.REACT_APP_OPENSEA_NFT_BASE_URL}/${process.env.REACT_APP_CONTRACT_ADDRESS}/${minted.tokenId}`;
  return (
    <>
      <p>
        Product <strong>{minted.pid}</strong> is already minted and assigned to this email.
      </p>
      <a href={url} rel="noopener noreferrer" target="_blank">
        View <strong>{minted.pid}</strong> on OpenSea
      </a>
    </>
  );
}

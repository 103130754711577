import Accordion, { AccordionSection } from "./Accordion";

export default function AccordionFaqNfts() {
  return (
    <Accordion id="faq-nfts">
      <AccordionSection
        title="Aren't NFT's a scam?"
        body={
          <>
            No. Scammers are the scam. There is currently a gold rush dominated by greed, but the
            technology is legit and here to stay. IMHO the blockchain &amp; NFTs bring at least four
            revolutionary changes to the digital art space:
            <ul style={{ listStyleType: "square" }}>
              <li>
                <strong>Proof of Ownership</strong>
                <br />
                The owner of a work is recorded (a NFT) on a public ledger (blockchain) permanently.
              </li>
              <br />
              <li>
                <strong>Provenance</strong>
                <br />
                Now that the owner of a digital work can be verified, provenance can help establish
                a work's 'pedigree' and contribute to a better understanding of historical trends in
                collecting. This will become hugely important in the future.
              </li>
              <br />
              <li>
                <strong>Residual Income</strong>
                <br />A commission on secondary sales is often built into the contract and the
                artist gets paid a commission automatically on resale of the artwork.
              </li>
              <br />
              <li>
                <strong>Opportunity & Optimism</strong>
                <br />
                Having established the first three points, pathways are now opening up for creatives
                to be able to <em>make a living</em> in the digital realm.
              </li>
            </ul>
          </>
        }
      />
      <AccordionSection
        title="Can't someone just 'right-click' or screen grab it?"
        body={
          <>
            Sure, but it's just a copy. You can have a copy of a Van Gogh but that doesn't make it
            valuable. That's why proof of ownership and provenance are so important. There are other
            issues around reproduction and copyright, but 'traditional' art has the same issues.
            There are avenues opening up to solve this, such as issuing a{" "}
            <a
              href="https://copyrightalliance.org/faqs/what-is-dmca-takedown-notice-process/"
              target={"_blank"}
              rel="noopener"
            >
              DMCA Takedown Notice
            </a>
            .
          </>
        }
      />
      <AccordionSection
        title="How do I buy one?"
        body={
          <>
            You need a digital wallet with some MATIC. See the general section above for creating
            one. If you already have ETH but need MATIC, I recommend using the{" "}
            <a href="https://bridge.umbria.network" target={"_blank"} rel="noopener">
              UMBRIA&nbsp;BRIDGE
            </a>
            , as it's one cheapest and fastest.
          </>
        }
      />
      <AccordionSection
        title="What Network / Token is used?"
        body={
          <>
            Polygon. Tokens are{" "}
            <a
              href="https://ethereum.org/en/developers/docs/standards/tokens/erc-721/"
              target={"_blank"}
              rel="noopener"
            >
              ERC-721
            </a>
            .
            {/* {useMetaMask().status !== "unavailable" && (
                <Box textAlign="center" mt={1} mb={1}>
                  <AddPolygon />
                </Box>
              )} */}
          </>
        }
      />
      <AccordionSection
        title="Where are the NFT Artworks stored?"
        body={
          <>
            On the distributed{" "}
            <a href="https://ipfs.io#how" target={"_blank"} rel="noopener">
              IPFS
            </a>{" "}
            network.
          </>
        }
      />
      <AccordionSection
        title="I already have ETHER, what's the easiest way to get MATIC?"
        body={
          <>
            Once of the cheapest and easiest is the{" "}
            <a href="https://bridge.umbria.network" target={"_blank"} rel="noopener">
              UMBRIA BRIDGE
            </a>
            . It on average costs $4-9 USD and only takes a couple of minutes.
          </>
        }
      />
      {/* <AccordionSection
        title="How do the attributes work?"
        body={
          <>
            When you create a product you enter a PID (product identifier); it's basically a
            username. The PID is prefixed to your product attributes. You can use your PID to look
            up other products that have your attributes. Each product is comprised of the following
            attributes and variations.
            <table
              style={{
                td: { borderColor: "#ffcf00" },
                marginBottom: "20px"
              }}
            >
              <thead>
                <tr>
                  <td style={{ borderBottom: "3px double #ffcf00" }}>
                    <strong>Attribute</strong>
                  </td>
                  <td style={{ borderBottom: "3px double #ffcf00" }}>
                    <strong>Variations</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Base</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>Aura</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td>Left Eye</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>Right Eye</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>Nose</td>
                  <td>6</td>
                </tr>
                <tr>
                  <td>Mouth</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>Social Media Halo</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>Social Media Left Eye</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>Social Media Right Eye</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>Social Media Mid</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>Social Media Beauty Spot</td>
                  <td>10</td>
                </tr>
              </tbody>
            </table>
            <p>
              The format is <strong>#PID-variation</strong>. The example below is for one product.
            </p>
            <table style={{ td: { borderColor: "#ffcf00" } }}>
              <thead style={{ th: { borderColor: "#ffcf00", fontWeight: "bold" } }}>
                <tr>
                  <td style={{ borderBottom: "3px double #ffcf00" }}>
                    <strong>Attribute</strong>
                  </td>
                  <td style={{ borderBottom: "3px double #ffcf00", minWidth: "100px" }}>
                    <strong>Example</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Base</td>
                  <td>#mary-1</td>
                </tr>
                <tr>
                  <td>Aura</td>
                  <td>#john-37</td>
                </tr>
                <tr>
                  <td>Left Eye</td>
                  <td>#alucard-3</td>
                </tr>
                <tr>
                  <td>Right Eye</td>
                  <td style={{ minWidth: "140px" }}>
                    #<strong>YOURPID</strong>-9
                  </td>
                </tr>
                <tr>
                  <td>Nose</td>
                  <td>#zodd-4</td>
                </tr>
                <tr>
                  <td>Mouth</td>
                  <td>#sisyphus-11</td>
                </tr>
                <tr>
                  <td>Social Media Halo</td>
                  <td>#utu.obey</td>
                </tr>
                <tr>
                  <td>Social Media Left Eye</td>
                  <td>#pant.cash</td>
                </tr>
                <tr>
                  <td>Social Media Right Eye</td>
                  <td>#meat</td>
                </tr>
                <tr>
                  <td>Social Media Mid</td>
                  <td>#fit.posy</td>
                </tr>
                <tr>
                  <td>Social Media Beauty Spot</td>
                  <td>#grim.satan</td>
                </tr>
              </tbody>
            </table>
          </>
        }
      /> */}
      <AccordionSection
        title="Are there rarities?"
        body={
          <>
            Seriously? Being immortalized in an original artwork not good enough for you? :-P
            <br />
            <br />
            Keep an eye out for products, that have the '<strong>MELM</strong>' PID. Products with
            this attribute have a part from my selfie.
            {/* One product in a hundred will have the rare 'creator' PID<sup>&#10013;</sup>. Products
              with this PID have an attribute from my selfie. ;-)
              <br />
              <br />
              <sup>&#10013;</sup>
              <Typography variant="caption">
                Excluding the Aura attribute which is randomly assigned.
              </Typography> */}
          </>
        }
      />
    </Accordion>
  );
}

import "./exhibition.css";
import melm from "./melm.svg";
import { useState } from "react";

export default function Exhibition({ show, onClose }) {
  const [zoomImgSrc, setZoomImgSrc] = useState();

  return zoomImgSrc ? (
    <div
      className="product"
      style={{
        backgroundImage: `url(${zoomImgSrc})`,
        position: "absolute",
        height: "100%",
        justifyContent: "end"
      }}
      onClick={() => setZoomImgSrc()}
    >
      <img alt="Zoom out" src="zoom-out.svg" width={40} height={40} />
    </div>
  ) : (
    <>
      {show && (
        <div
          className="close-create"
          style={{ position: "fixed", textShadow: "1px 1px white", padding: "10px" }}
          onClick={() => onClose()}
        >
          X
        </div>
      )}
      <div className="main-content" id="exhibition">
        <div style={{ margin: "30px" }}>
          <h1 style={{ color: "black" }}>CONSTRUCTED REALITIES</h1>
          <strong>
            <p>
              Matthew Meharry &mdash; <small>THE PRODUCT.</small>
              <br />
              Brett Phillips &mdash; <small>Spectrum &amp; Semiotics Series'.</small>
            </p>
          </strong>
          <small>
            2nd - 26th March 2023.
            <br />
            3/126 Oxford Terrace.
            <br />
            The TERRACE.
            <br />
            Ōtautahi. Christchurch.
          </small>
          {/* <p>
            <strong>In the Media</strong>
          </p> */}
          <div style={{ fontSize: "small", color: "white" }}>
            <p>
              The Press &mdash;{" "}
              <a
                href="https://www.stuff.co.nz/entertainment/arts/131375418/10-exhibitions-to-see-in-march"
                target="_blank"
                rel="noopener"
                referrerPolicy="origin"
              >
                10 Exhibitions to see in March
              </a>
            </p>
            <p>
              Radio &mdash;{" "}
              <a
                href="https://rdu.org.nz/podcast/constructed-realities"
                target="_blank"
                rel="noopener"
                referrerPolicy="origin"
              >
                RDU 98.5fm Breakfast show
              </a>
            </p>
            <p>
              LiVS &mdash;{" "}
              <a
                href="https://www.livs.org.nz/project/constructed-realities"
                target="_blank"
                rel="noopener"
                referrerPolicy="origin"
              >
                Constructed Realities project
              </a>
            </p>
          </div>
          <p>
            From simple concepts to more complex ideas, "Constructed Realities" explores themes of
            personal identity, cultural hegemony, social engineering and self-awareness. Normalising
            the conversation around these themes intends to inform viewers that the effect of
            constructing reality lies with ourselves, and of the mechanisms that we engage with
            consciously and subconsciously. Paint on canvas and interactive digital mediums are used
            to create art objects, around which the conversations are formed.
          </p>
        </div>
        <div
          style={{
            height: "4vh",
            width: "100%",
            backgroundColor: "#1ec0ae",
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%)"
          }}
        />
        <div style={{ marginTop: "-4vh" }}>
          <ul className="ul">
            <li className="li">
              <img
                className="img"
                src="DSCF6288.jpg"
                onClick={(e) => setZoomImgSrc(e.target.src)}
              />
            </li>
            <li className="li">
              <img
                className="img"
                src="DSCF6406.jpg"
                onClick={(e) => setZoomImgSrc(e.target.src)}
              />
            </li>
            <li className="li">
              <img
                className="img"
                src="DSCF6525.jpg"
                onClick={(e) => setZoomImgSrc(e.target.src)}
              />
            </li>
            <li className="li">
              <img
                className="img"
                src="DSCF6526.jpg"
                onClick={(e) => setZoomImgSrc(e.target.src)}
              />
            </li>
            <li className="li">
              <img
                className="img"
                src="DSCF6337.jpg"
                onClick={(e) => setZoomImgSrc(e.target.src)}
              />
            </li>
            <li className="li">
              <img
                className="img"
                src="DSCF6303.jpg"
                onClick={(e) => setZoomImgSrc(e.target.src)}
              />
            </li>
            <li className="li">
              <img
                className="img"
                src="DSCF6417.jpg"
                onClick={(e) => setZoomImgSrc(e.target.src)}
              />
            </li>
            <li className="li">
              <img
                className="img"
                src="DSCF6336.jpg"
                onClick={(e) => setZoomImgSrc(e.target.src)}
              />
            </li>
            <li className="li">
              <img
                className="img"
                src="DSCF6349.jpg"
                onClick={(e) => setZoomImgSrc(e.target.src)}
              />
            </li>
            <li className="li">
              <img
                className="img"
                src="DSCF6386.jpg"
                onClick={(e) => setZoomImgSrc(e.target.src)}
              />
            </li>
            <li className="li">
              <img
                className="img"
                src="DSCF6320.jpg"
                onClick={(e) => setZoomImgSrc(e.target.src)}
              />
            </li>
            <li className="li">
              <img
                className="img"
                src="DSCF6402.jpg"
                onClick={(e) => setZoomImgSrc(e.target.src)}
              />
            </li>
            <li className="li">
              <img
                className="img"
                src="IMG_8513.jpg"
                onClick={(e) => setZoomImgSrc(e.target.src)}
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="footer">
        <a href="https://www.melm.nz" target={"_blank"} rel="noopener">
          <img className="melm" src={melm} />
        </a>
        <p>&copy; MELM {new Date().getFullYear()}</p>
      </div>
    </>
  );
}

// NOTE see: https://github.com/ethers-io/ethers.js/issues/2828

const { BigNumber, utils } = require("ethers");

function parseGwei(data) {
  return utils.parseUnits(Math.ceil(data).toString(), "gwei");
}

export function getGasDefaults() {
  const gasFeeDefault = process.env.REACT_APP_GAS_FEE_DEFAULT_GWEI;
  console.log(`Gas fee default: ${gasFeeDefault} gwei`);
  const defaultFeeGwei = BigNumber.from(parseGwei(gasFeeDefault));
  return {
    maxFeePerGas: defaultFeeGwei,
    maxPriorityFeePerGas: defaultFeeGwei
  };
}

async function getGasStationFees() {
  const gasStationUrl = process.env.REACT_APP_GAS_STATION_URL;
  console.log(`Getting Gas Station fees from ${gasStationUrl}`);
  let gas = getGasDefaults();

  try {
    const gasStationFeesResponse = await fetch(gasStationUrl);
    if (!gasStationFeesResponse.ok) throw "Gas station response error.";
    const gasStationFees = await gasStationFeesResponse.json();

    console.log("Gas station fees: ", gasStationFees);

    gas.maxFeePerGas = BigNumber.from(parseGwei(gasStationFees.fast.maxFee));
    gas.maxPriorityFeePerGas = BigNumber.from(parseGwei(gasStationFees.fast.maxPriorityFee));
  } catch (error) {
    console.log(`${error.message}. Using default gas.`);
    console.log(error);
  } finally {
    return gas;
  }
}

export default async function calcGas({ contract, methodName, args, cost }) {
  const gasStationFees = await getGasStationFees();

  console.log(`Getting gas estimate from ${methodName} with args:`, args);
  const gasEstimate = await contract.estimateGas[methodName](...Object.values(args), {
    value: cost
  });

  console.log("Gas estimate: ", gasEstimate);

  return {
    ...gasStationFees,
    gasLimit: BigNumber.from(gasEstimate)
  };
}

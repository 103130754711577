export default function Alert({ title, body, style, btnText, onClose }) {
  const handleClose = () => {
    onClose();
  };
  return (
    <div className="overlay" style={{ left: title || body ? "0%" : "-100%" }}>
      <div
        className="overlay-content"
        id="overlay"
        style={style ? style : { top: "33%", textAlign: "center" }}
      >
        {title}
        {body}
        <div style={{ textAlign: "center" }}>
          <button style={{ margin: "20px 0" }} onClick={handleClose}>
            {btnText ? btnText : "OK"}
          </button>
        </div>
      </div>
    </div>
  );
}

import { useMetaMask } from "metamask-react";
import { useEffect, useState } from "react";
import { ethers, BigNumber } from "ethers";
import Button from "./Button";
import utils from "./Utils";

export default function ConnectWallet({ onContinue, onCancel, onAccountChange, action }) {
  const { status, chainId, account, connect, addChain } = useMetaMask();
  const [connected, setConnected] = useState(false);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);
  const [checkingBalance, setCheckingBalance] = useState(false);
  const [balance, setBalance] = useState(null);
  const COST_NFT_MATIC = Number.parseFloat(process.env.REACT_APP_COST_NFT_MATIC);
  const COST_NFT_DOLLARS = process.env.REACT_APP_COST_NFT_DOLLARS;
  const COST_NFT_ETH = process.env.REACT_APP_COST_NFT_ETH;

  useEffect(() => {
    setConnected(status === "connected");
  }, [status]);

  useEffect(() => {
    setIsCorrectNetwork(process.env.REACT_APP_CHAIN_ID === chainId);
  }, [chainId]);

  useEffect(() => {
    if (connected && account !== null) {
      async function getBalance() {
        setCheckingBalance(true);
        const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
        provider.getBalance(account).then((balance) => {
          console.log("Raw ballance: ", balance);
          setCheckingBalance(false);
          const matic = Number.parseFloat(utils.formatToMatic(balance));
          setBalance(matic);
        });
      }

      getBalance();
    }
  }, [connected, account]);

  useEffect(() => {
    if (account) {
      onAccountChange(account);
    }
  }, [account, onAccountChange]);

  const getUnavailable = () => {
    return (
      <div>
        <p>MetaMask wallet not detected or not using MetaMask's Web3 browser.</p>If you have
        MetaMask installed use the inbuilt browser to access this site: Open MetaMask, tap on the
        Browser icon at the bottom of the screen, then enter www.theproduct.social
        <p>
          Otherwise please{" "}
          <a href="https://www.metamask.io" rel="noopener noreferrer" target="_blank">
            install
          </a>{" "}
          MetaMask.
        </p>
      </div>
    );
  };

  const getBalanceSection = () => {
    if (balance < COST_NFT_MATIC) {
      return (
        <>
          <div>
            Wallet <strong>{utils.truncateAddress(account)}</strong> does not have enough funds to
            mint the NFT. Either change account or add at least <strong>{COST_NFT_MATIC}</strong>{" "}
            {process.env.REACT_APP_CHAIN_SYMBOL}.
          </div>
          <p>
            Balance: <strong>{balance}</strong> {process.env.REACT_APP_CHAIN_SYMBOL}
          </p>
        </>
      );
    }

    return (
      <div>
        The cost of minting the Product is:
        <p>
          <strong>{COST_NFT_MATIC}</strong> {process.env.REACT_APP_CHAIN_SYMBOL}.<br />
          <small>
            (Approx {COST_NFT_ETH} ETH. ${COST_NFT_DOLLARS}
            NZD).
          </small>
        </p>
        <p>
          It will be minted to wallet address <strong>{`${utils.truncateAddress(account)}`}</strong>
        </p>
        <p>
          Balance: <strong>{balance}</strong> {process.env.REACT_APP_CHAIN_SYMBOL}
        </p>
      </div>
    );
  };

  // switches to it if already exists
  const addNetwork = () => {
    const params = {
      chainId: process.env.REACT_APP_CHAIN_ID,
      chainName: `${process.env.REACT_APP_CHAIN_NAME} Mainnet`,
      rpcUrls: [process.env.REACT_APP_CHAIN_RPC],
      nativeCurrency: {
        name: process.env.REACT_APP_CHAIN_SYMBOL,
        symbol: process.env.REACT_APP_CHAIN_SYMBOL,
        decimals: 18
      },
      blockExplorerUrls: [process.env.REACT_APP_CHAIN_EXPLORER_URL]
    };
    return addChain(params);
  };

  return (
    <>
      <div className="connect-wallet-content">
        {status === "notConnected" && <>MetaMask not connected.</>}
        {status === "initializing" && <p>Synchronizing with MetaMask...</p>}
        {status === "unavailable" && getUnavailable()}
        {status === "connecting" && <>Connecting to MetaMask...</>}
        {connected && !isCorrectNetwork && (
          <>
            Wrong network. Products are minted on the {process.env.REACT_APP_CHAIN_NAME} blockchain.
          </>
        )}
        {connected && isCorrectNetwork && checkingBalance && <>Checking for sufficient funds...</>}
        {connected && isCorrectNetwork && !checkingBalance && getBalanceSection()}
      </div>
      <div>
        <Button disabled={checkingBalance} onClick={() => onCancel()} label="<" />
        {status === "notConnected" && <Button onClick={connect} label="Connect" />}
        {connected && !isCorrectNetwork && (
          <Button
            clearMarginRight
            onClick={() => addNetwork()}
            label={`Switch to ${process.env.REACT_APP_CHAIN_NAME}`}
          />
        )}
        {connected && isCorrectNetwork && !checkingBalance && balance > COST_NFT_MATIC && (
          <Button clearMarginRight onClick={() => onContinue()} label="Continue" />
        )}
      </div>
    </>
  );
}

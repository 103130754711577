import { ethers } from "ethers";
const abi = require(`./${process.env.REACT_APP_ABI_ENV}-abi.json`);

// For local testing
// async function getAbi() {
//   const abiResponse = await fetch(`${process.env.REACT_APP_ENV}-abi.json`);
//   return abiResponse.json();
// }

const utils = {
  truncateAddress: (address) => {
    const crcAddy = ethers.utils.getAddress(
      address === null ? ethers.constants.AddressZero : address
    );
    return `${crcAddy.substring(0, 5)}...${crcAddy.substring(crcAddy.length - 4)}`;
  },
  formatAddress: (address) => {
    return ethers.utils.getAddress(address);
  },
  formatToMatic: (gwei) => {
    return Number.parseFloat(ethers.utils.formatUnits(gwei, "ether")).toFixed(4);
  },
  getMetamaskProviderContract: () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    //const abi = await getAbi();

    console.log("Getting contract: ", process.env.REACT_APP_CONTRACT_ADDRESS);
    return new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, abi, signer);

    // using RPC instead of Web3Provider (metamask) due to consistent 'header not found error'
    //const provider = new JsonRpcProvider(process.env.REACT_APP_RPC_URL);
    //return new Contract(CONTRACT_ADDRESS, abi, provider);
  },
  getRpcProviderContract: () => {
    const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
    return new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, abi, provider);
  },
  getTxError: async (txnHash) => {
    const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
    const inter = new ethers.utils.Interface(abi);
    console.log("Getting txn:", txnHash);
    const tx = await provider.getTransaction(txnHash);

    let code = "";
    let revertReason = "N/A";
    try {
      code = await provider.call(tx, Number(tx.blockNumber));
      console.log("code1: ", code);
    } catch (err) {
      console.log({ err });
      console.log("code2: ", code);
      if (err.statusCode === 403) {
        console.log(
          "Warning: No access to archive state - you will need to provide an providerURI to get older revert reasons"
        );
      }
      try {
        code = await provider.call(tx);
        console.log("code3: ", code);
      } catch (err2) {
        console.log({ err2 });
        console.log("code4: ", code);
        revertReason = ethers.utils.toUtf8String(`0x${code.substring(138)}`);
      }
    }
    console.log("Revert reason", revertReason);
  },
  getScannerError: async (txnHash) => {
    console.log("Getting error...");
    //const query = `module=transaction&action=gettxreceiptstatus&txhash=${txnHash}`;
    const query = `module=transaction&action=getStatus&txhash=${txnHash}`;
    const url = process.env.REACT_APP_POLYGONSCAN_API.replace("QUERY", query);
    console.log("URL: ", url);
    const response = await fetch(url);
    console.log("Status: ", await response.text());
  },
  log: async (entry) => {
    try {
      console.log("Logging error to AWS...");
      const loggerResponse = await fetch(utils.getEndPoint("logger"), {
        method: "POST",
        body: JSON.stringify(entry)
      });

      if (!loggerResponse.ok) {
        console.log("Could not POST to logger, response not OK.");
      }
      console.log("DONE logging to AWS!");
    } catch (loggerError) {
      console.log("Could not POST to logger, error: ", loggerError);
    }
  },
  getEndPoint: (route, qs) => {
    return `${process.env.REACT_APP_API_GATEWAY}/${route}${qs ? "?" + qs : ""}`;
  }
};

export default utils;

import { useState } from "react";
import Button from "./Button";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const Content = ({ status, message, onSubmitted }) => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    onSubmitted({
      EMAIL: email
    });
  };

  return (
    <>
      <input
        type="text"
        style={{ marginBottom: "10px" }}
        value={email}
        onChange={handleEmailChange}
        placeholder="Enter email"
      />
      <Button
        clearMarginRight
        busy={status === "sending"}
        label="Subscribe"
        onClick={handleSubmit}
      />
      <div style={{ color: "white" }}>
        {message}
        <br />
      </div>
    </>
  );
};

const url =
  "https://melm.us14.list-manage.com/subscribe/post?u=d181951a3b78219fa78891946&id=085ff9dad9";

// use the render prop and your custom form
export const SubscribeForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <Content status={status} message={message} onSubmitted={(formData) => subscribe(formData)} />
    )}
  />
);

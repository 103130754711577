export function ImportIntoMetaMask({ contractAddress, tokenId }) {
  return (
    <ol style={{ textAlign: "left" }}>
      <li>
        In MetaMask, tap on <div className="hamburger" />, select Wallet.
      </li>
      <li>
        Tap on the <strong>NFTs</strong> tab in the middle of the screen.
      </li>
      <li>
        Scroll down and tap on <strong>Import NFTs</strong>.
      </li>
      <li>
        Enter
        <div style={{ wordBreak: "break-all", width: "80%" }}>
          <strong>{contractAddress}</strong>
        </div>
        in the address field.
      </li>
      <li>
        Enter <strong>{tokenId}</strong> in the ID field.
      </li>
      <li>
        Tap on <strong>Import</strong>.
      </li>
      <p>
        <small>NOTE: It may take a few minutes for it to appear in your wallet.</small>
      </p>
    </ol>
  );
}

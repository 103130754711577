const Menu = ({ onNavItemClick }) => (
  <div id="nav" className="menu-li">
    <li>
      <a onClick={() => onNavItemClick("create")}>--create</a>
    </li>
    <li>
      <a onClick={() => document.getElementById("faq").scrollIntoView()}>--faq</a>
    </li>
    <li>
      <a onClick={() => document.getElementById("demo").scrollIntoView()}>--demo</a>
    </li>
    <li>
      <a onClick={() => document.getElementById("engage").scrollIntoView()}>--engage</a>
    </li>
    <li>
      <a onClick={() => onNavItemClick("exhibition")}>--exhibition</a>
    </li>
    <li>
      <a onClick={() => onNavItemClick("opensea")}>--opensea</a>
    </li>
  </div>
);
export const Nav = ({ showMenu, onNavItemClick, onClose, onClick }) => {
  return (
    <div tabIndex={0} className="nav-container" onClick={() => onClick()} onBlur={() => onClose()}>
      <div className="terminal">
        ~$<div className="blink">&#9776;</div>
      </div>
      {showMenu && <Menu onNavItemClick={(item) => onNavItemClick(item)} />}
    </div>
  );
};

import { SubscribeForm } from "./SubscribeForm";
import at from "./at.svg";

export default function ({ bgColor }) {
  return (
    <div style={{ backgroundColor: bgColor }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "96vh",
          textAlign: "center"
        }}
      >
        <h1>EMAIL</h1>
        <span style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
          hello
          <img src={at} style={{ width: "7vh", padding: "0 2px 0 2px" }} />
          theproduct.social
        </span>
        <h1>NEWSLETTER</h1>
        <span style={{ marginBottom: "10px" }}>Stay informed. Old Skool.</span>
        <span style={{ width: "75%", maxWidth: "600px" }}>
          <SubscribeForm />
        </span>
      </div>
    </div>
  );
}

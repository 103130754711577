import { useState } from "react";
import "./accordion.css";

function AccordionSegment({ id, title, body, expanded, onChange }) {
  return (
    <>
      <button
        id={id}
        className={`accordion-header ${expanded ? "accordion-expanded accordion-header-bold" : ""}`}
        onClick={() => onChange(id)}
      >
        <span style={{ minHeight: "42px" }}>{title}</span>
      </button>
      <div
        className="accordion-panel"
        style={{
          height: expanded ? "unset" : 0,
          padding: expanded ? "10px 10px 30px 10px" : 0,
          borderBottom: expanded ? "1px dotted rgba(0, 0, 0, 0.12)" : 0
        }}
      >
        {body}
      </div>
    </>
  );
}

export function AccordionSection(props) {
  return <AccordionSegment {...props} />;
}

export default function Accordion(props) {
  const { id: accordionId, defaultPanel, onSectionChange } = props;
  const [expandedPanel, setExpandedPanel] = useState(defaultPanel);

  const handleChange = (panel) => {
    if (panel === expandedPanel) {
      setExpandedPanel("");
      if (onSectionChange) {
        onSectionChange({ panel, expanded: false });
      }
      return;
    }

    setExpandedPanel(panel);

    setTimeout(() => {
      document.getElementById(panel).scrollIntoView();
      if (onSectionChange) {
        onSectionChange({ panel, expanded: true });
      }
    }, 100);
  };

  return (
    <div>
      {props.children.map((child, i) => {
        const id = accordionId + i;
        return (
          <AccordionSegment
            {...child.props}
            id={id}
            key={id}
            expanded={expandedPanel === id}
            onChange={() => handleChange(id)}
          />
        );
      })}
    </div>
  );
}

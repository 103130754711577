import Cropper from "react-easy-crop";
import { useCallback, useState, useRef } from "react";
//import isFace from "./facefinder";

export default function SelfieCropper({ disable, imageSrc, onAreaCropped }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const imageEl = useRef(null);

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      onAreaCropped({ rotation, ...croppedArea });
    },
    [rotation, onAreaCropped]
  );

  return (
    <>
      <Cropper
        image={imageSrc}
        restrictPosition={false}
        aspect={1240 / 1754}
        crop={crop}
        showGrid={false}
        zoom={zoom}
        rotation={rotation}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        onRotationChange={setRotation}
        objectFit="vertical-cover"
        minZoom={0.5}
        style={{
          containerStyle: {
            pointerEvents: disable ? "none" : "auto"
          },
          cropAreaStyle: {
            border: 0,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage: "url('./base.png')"
          }
        }}
      />
      <img ref={imageEl} src={imageSrc} style={{ display: "none " }} />
    </>
  );
}

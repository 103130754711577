import React from "react";
export default function Button({
  busy,
  disabled,
  label,
  imgSrc,
  imgAlt,
  clearMarginRight,
  clearMarginBottom,
  className,
  style,
  onClick
}) {
  const marginValue = "15px";
  const styles = {
    marginRight: clearMarginRight ? 0 : marginValue,
    marginBottom: clearMarginBottom ? 0 : marginValue
  };
  if (imgSrc && !busy) {
    styles.padding = "3px 5px";
    styles.fontSize = 0;
  }
  return (
    <button
      style={{ ...styles, ...style }}
      className={busy ? "busy" : className}
      disabled={disabled || busy ? "disabled" : ""}
      onClick={() => {
        !!onClick && onClick();
      }}
    >
      {busy ? "" : imgSrc ? <img alt={imgAlt} src={imgSrc} height="21px" /> : label}
    </button>
  );
}
